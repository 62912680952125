/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
import { getItem, postFileItem, updateFileItem } from "../services/api.svs";
import { useHistory } from "react-router-dom";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText,
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { Blocks } from 'react-loader-spinner';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

function ProductManager(props) {
  const [isFilled, setIsFilled] = useState(props.value !== undefined)
  const [filledProduct, setFilledTProduct] = useState(isFilled ? props.value : [])

  const [IsLoaded, setIsLoaded] = useState(false);
  const [token, setToken] = useState([])
  const [productId, setId] = useState(isFilled ? filledProduct.productId : 0);
  const [productName, setName] = useState(isFilled ? filledProduct.productName : '');
  const [file, setFile] = useState([]);
  const [smallFile, setSmallFile] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productType, setProductType] = useState(isFilled ? filledProduct.productType.productTypeName : []);
  const [benefitsInput, setBenefitsInput] = useState('');
  const [benefits, setBenefit] = useState(isFilled ? filledProduct.benefits : [])
  const [description, setDescription] = useState(isFilled ? filledProduct.description : '');
  const [chosenSizes, setSizes] = useState(isFilled ? filledProduct.sizes : [])
  const [takealotUrl, setTakealotUrl] = useState(isFilled ? filledProduct.takealotUrl : '');
  const [makroUrl, setMakroUrl] = useState(isFilled ? filledProduct.makroUrl : '');
  const [leroyAndMerlinUrl, setLeroyAndMerlinUrl] = useState(isFilled ? filledProduct.leroyAndMerlinUrl : '');
  const [specs, setSpecs] = useState(isFilled ? filledProduct.specs : '');
  const [visible, setVisible] = useState(isFilled ? filledProduct.visible : false);
  const notificationAlertRef = React.useRef(null);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState(false);
  const sizes = new Array('150ml', '200ml', '300ml', '400ml', '500ml', '750ml', '1l', '5l', '20l', '210l', '500g', '5kg', '15kg', 'Bulk');
  const history = useHistory();


  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getProductNames = async () => {
      setToken((await Auth.currentSession()).getIdToken().getJwtToken())
    }
    getProductNames()

    const getProductTypes = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/product_types', token).then(resp => {
        setProductTypes(resp.data)
        setProductType(resp.data[0].productTypeName)
        setIsLoaded(true)
      }).catch(resp => {
        setProductTypes([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getProductTypes()

  }, [])

  const submit = () => {
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productTypeName", productType);
    formData.append("description", description);
    formData.append("takealotUrl", takealotUrl);
    formData.append("makroUrl", makroUrl);
    formData.append("leroyAndMerlinUrl", leroyAndMerlinUrl);
    formData.append("sizes", chosenSizes);
    formData.append("benefits", filtered);
    formData.append("specs", specs);
    formData.append("file", file);
    formData.append("smallFile", smallFile);
    formData.append("visible", visible);
    if (isFilled) {
      formData.append("productId", productId)
      updateFileItem('/admin/products', formData, token).then(resp => {
        // setItems(resp.data)
        setIsLoaded(true)
        let state = "success"
        // notify("tl", message, "success")

        history.push("/products", state);
      }).catch(resp => {
        setIsLoaded(true)
        let state = "danger"
        // notify("tl", message, "success")

        history.push("/products", state);
      })
    }
    else {
      postFileItem('/admin/products', formData, token).then(resp => {
        setIsLoaded(true)
        let state = "success"
        // notify("tl", message, "success")

        history.push("/products", state);
      }).catch(resp => {
        // setItems([])
        setIsLoaded(true)
        let state = "danger"
        // notify("tl", message, "success")

        history.push("/products", state);
      })
    }
  }

  //seperate benefits
  const setBenefits = (e) => {

    if (e.target.value.match(",")) {
      setFiltered(e.target.value.split(","))
      setFilter(true)
    }
    setBenefitsInput(e.target.value)

  }

  const onChangeMulti = (event) => {
    let opts = [], opt;
    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];
      if (opt.selected) {
        opts.push(opt.value);
      }
    }
    // this.setState({ StatusFilter: opts });
    setSizes(opts)
  }

  const toggleVisible = () => {
    setVisible(!visible)
    console.log(visible)
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title"> {`${isFilled ? 'Update' : 'Add'}`} Product</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            placeholder="Product Name"
                            type="text"
                            value={productName}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>
                            Product Type
                          </label>
                          <Input
                            id="productTypeSelect"
                            name="productType"
                            type="select"
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                          >
                            {productTypes.map((item) => {
                              return (<option key={item.productTypeName}>
                                {item.productTypeName}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            id="exampleText"
                            name="description"
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Benefits (Comma Seperated)</label>
                          <Input
                            id="benefits"
                            name="benefit"
                            type="textarea"
                            // value={benefits}
                            // onChange={(e) => setBenefitsInput(e.target.value)}
                            onChange={(e) => setBenefits(e)}
                          />
                        </FormGroup>
                        {filter ? <FormText>
                          {filtered.map((benefit) => {
                            return (<p>{benefit}</p>)
                          })}
                        </FormText> : <></>}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            Available Sizes
                          </Label>
                          <Input
                            id="sizes"
                            multiple
                            name="sizes"
                            type="select"
                            value={chosenSizes}
                            // value={this.state.StatusFilter}
                            onChange={(e) => onChangeMulti(e)}
                          >
                            {sizes.map((item) => {
                              return (<option key={item}>
                                {item}
                              </option>);
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Specs</label>
                          <Input
                            id="specs"
                            name="specs"
                            type="textarea"
                            value={specs}
                            onChange={(e) => setSpecs(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Takealot</label>
                          <Input
                            placeholder="https://link.to/takealot"
                            type="text"
                            value={takealotUrl}
                            onChange={(e) => setTakealotUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Makro</label>
                          <Input
                            placeholder="https://link.to/makro"
                            type="text"
                            value={makroUrl}
                            onChange={(e) => setMakroUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Leroy & Merlin</label>
                          <Input
                            placeholder="https://link.to/leroy&merlin"
                            type="text"
                            value={leroyAndMerlinUrl}
                            onChange={(e) => setLeroyAndMerlinUrl(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>

                          <FormControlLabel
                            control={<Checkbox onChange={toggleVisible} />}
                            label={<Typography style={{ fontSize: "0.7500000025rem" }}>Visible to Customers</Typography>}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>
                            Image
                          </label>
                          <Input
                            id="uploadFile"
                            name="file"
                            type="file"
                            style={{
                              border: "10px", borderColor: "rgba(43, 53, 83, 1)", borderRadius: "0.4285rem",
                              fontSize: "0.7500000025rem"
                            }}
                            // value={selectile}
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                          <FormText>
                            Upload an image from your device which will be used for this product.
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>
                            Small Image
                          </label>
                          <Input
                            id="uploadSmallFile"
                            name="smallFile"
                            type="file"
                            style={{
                              border: "10px", borderColor: "rgba(43, 53, 83, 1)", borderRadius: "0.4285rem",
                              fontSize: "0.7500000025rem"
                            }}
                            // value={selectile}
                            onChange={(e) => setSmallFile(e.target.files[0])}
                          />
                          <FormText>
                            Upload a smaller version of this image from your device which will be used for this product.
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit" onClick={submit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default ProductManager;

