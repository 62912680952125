import React, { useState, useEffect } from 'react';

import { Bar, Pie } from "react-chartjs-2";
import { getItem } from "../../services/api.svs";
import { Auth } from 'aws-amplify';

function ProductTypeStatsComponent(props) {

  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);

  let status_options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        // align: 'start',
        text: 'Products Per Type'
      }
    }
  };

  let statusChart = {
    stats: (canvas) => {
      return {
        labels: label,
        datasets: [
          {
            label: "products",
            backgroundColor: ['rgba(255, 159, 64, 0.2)'],
            borderColor: ['rgba(255, 159, 64, 1)'],
            borderWidth: 1,
            data: data
          }
        ]
      };
    },
    options: status_options
  };

  useEffect(() => {
    const getStats = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/dashboard/productTypeStats', token).then(resp => {
        for (const [key, value] of Object.entries(resp.data)) {
          setData(data => [...data, value.value]);
          setLabel(label => [...label, value.key]);

        }


      }).catch(resp => {
      })
      //stop loading when data is fetched
    }
    getStats()
  }, [])

  return (
    <>
      <Bar
        data={statusChart["stats"]}
        options={statusChart.options}
      />
    </>
  );
};

export default ProductTypeStatsComponent;
