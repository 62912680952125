/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
import { postFileItem, updateFileItem } from "../services/api.svs";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormText
} from "reactstrap";
import { Auth } from 'aws-amplify';
import { Blocks } from 'react-loader-spinner';

function ProductTypeManager(props) {
  const [isFilled, setIsFilled] = useState(props.value !== undefined)
  const [filledType, setFilledType] = useState(isFilled ? props.value : [])
  const [IsLoaded, setIsLoaded] = useState(false);
  const [token, setToken] = useState([])
  const [productTypeName, setName] = useState(isFilled ? filledType.productTypeName : '');
  const [productTypeId, setId] = useState(isFilled ? filledType.productTypeId : 0);
  const [file, setSelectedFile] = useState([]);
  const notificationAlertRef = React.useRef(null);
  const [dataToSend, setDataToSend] = useState([])
  const [formData, setFormData] = useState(new FormData())
  const history = useHistory();

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {

    const getProductTypes = async () => {
      setToken((await Auth.currentSession()).getIdToken().getJwtToken())
      setIsLoaded(true)
    }
    getProductTypes()
  }, [])

  const submit = () => {
    const formData = new FormData();
    formData.append("productTypeName", productTypeName);
    formData.append("file", file);
    if (isFilled) {
      formData.append("productTypeId", productTypeId)
      updateFileItem('/product_types', formData, token).then(resp => {

        setIsLoaded(true)
        let state = "success"
        // notify("tl", message, "success")
        
        history.push("/product-types", state);
      }).catch(resp => {
        // setItems([])
        setIsLoaded(true)
        let state = "danger"
        // notify("tl", message, "success")
        
        history.push("/product-types", state);
      })
    }
    else {
      postFileItem('/product_types', formData, token).then(resp => {
        setIsLoaded(true)
        let state = "success"
        // notify("tl", message, "success")
        
        history.push("/product-types", state);
      }).catch(resp => {
        // setItems([])
        setIsLoaded(true)
        let state = "danger"
        // notify("tl", message, "success")
        
        history.push("/product-types", state);
      })
    }
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="title"> {`${isFilled ? 'Update' : 'Add'}`} Product Type</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            placeholder="Product Type Name"
                            type="text"
                            value={productTypeName}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col md="12">
                        {/* <FormGroup>
                        <label>Address</label>
                        <Input
                          defaultValue=""
                          placeholder="https://url.to/image.png"
                          type="text"
                        />
                      </FormGroup> */}
                        <FormGroup>
                          <label>
                            File
                          </label>
                          <Input
                            id="uploadFile"
                            name="file"
                            type="file"
                            style={{
                              border: "1px rgba(43, 53, 83, 1) solid", borderRadius: "0.4285rem",
                              // padding: "40px",
                              // fontSize: "0.7500000025rem"
                            }}
                            // value={selectile}
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                          />
                          <FormText>
                            Upload an image from your device which will be used for this product type.
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit" onClick={submit}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default ProductTypeManager;

