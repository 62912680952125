import React, { useState, useEffect } from 'react';

import { Line, Pie } from "react-chartjs-2";
import { getItem } from "../../services/api.svs";
import { Auth } from 'aws-amplify';
import { consoleSandbox } from '@sentry/utils';

function MessageStatusMonthlyComponent(props) {

  const [data, setData] = useState([]);
  const [unseen, setOngoing] = useState([]);
  const [seen, setCompleted] = useState([]);
  const [processed, setAbandoned] = useState([]);
  const [label, setLabel] = useState([]);

  let status_options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        // align: 'start',
        text: 'Message Status'
      }
    }
  };

  let statusChart = {
    stats: (canvas) => {
      return {
        labels: label,
        datasets: [
          {
            label: "Unseen",
            backgroundColor: ['rgba(255, 99, 132, 0.2)'],
            borderColor: [
              'rgba(255, 99, 132, 1)'],
            borderWidth: 1,
            data: unseen,
            lineTension: 0.4,
            fill: true
          },
          {
            label: "Seen",
            backgroundColor: ['rgba(54, 162, 235, 0.2)'],
            borderColor: [
              'rgba(54, 162, 235, 1)'],
            borderWidth: 1,
            data: seen,
            lineTension: 0.4,
            fill: true
          }, {
            label: "Processed",
            backgroundColor: ['rgba(75, 192, 192, 0.2)'],
            borderColor: [
              'rgba(75, 192, 192, 1)'],
            borderWidth: 1,
            data: processed,
            lineTension: 0.4,
            fill: true
          }
        ]
      };
    },
    options: status_options
  };

  useEffect(() => {
    const getStats = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/dashboard/monthlyMessageStats', token).then(resp => {
        for (const [key, value] of Object.entries(resp.data.labels)) {
          setLabel(label => [...label, value]);

        }

        for (const [keyset, valueset] of Object.entries(resp.data.dataset)) {
          for (const [key, value] of Object.entries(valueset.data)){
         if (valueset.label == "Unseen") setOngoing(unseen => [...unseen, value]);
         if (valueset.label == "Seen") setCompleted(seen => [...seen, value]);
         if (valueset.label == "Processed") setAbandoned(processed => [...processed, value]);
          }
        }


      }).catch(resp => {
      })
      //stop loading when data is fetched
    }
    getStats()
  }, [])

  return (
    <>
      <Line
        data={statusChart["stats"]}
        options={statusChart.options}
      />
    </>
  );
};

export default MessageStatusMonthlyComponent;
