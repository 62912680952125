import React, { useState, useEffect } from 'react';
import { getItem, getFileItem, deleteItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import ProductManager from './ProductManager';

function Product(props) {
    const [IsLoaded, setIsLoaded] = useState(false);
    const [product, setProduct] = useState([])
    const [showUpdate, setShowUpdate] = useState(false);
    const [token, setToken] = useState([])
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();

    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        const getProduct = async () => {
            const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
            getItem(`/admin/products/coupled/${props.location.state.productId}`, token).then(resp => {
                setProduct(resp.data)
                setIsLoaded(true)

            }).catch(resp => {
                setProduct([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
        }
        getProduct()
    }, [])


    // const onButtonClick = () => {
    //     const token = window.localStorage["Access_Token"]
    //     getFileItem(`storage/download/client?id=${props.location.state.contactId}`, token).then(response => {
    //         const filename = response.headers.get('Content-Disposition').split('filename=')[1];
    //         // response.blob().then(blob => {
    //         let url = window.URL.createObjectURL(new Blob([response.data]));
    //         let a = document.createElement('a');
    //         a.href = url;
    //         a.download = filename;
    //         a.click();
    //         //   }).catch(resp => {
    //         //     console.log(resp)
    //         // });
    //     });
    // }

    const toggleShowUpdate = () => {
        setShowUpdate(showUpdate ? false : true)
    }

    const deleteProduct = () => {
        deleteItem(`/products/${props.location.state.productId}`, token).then(resp => {
            let state = "success"
        // notify("tl", message, "success")
        
        history.push("/products", state);

        }).catch(resp => {
            setIsLoaded(true)
            let state = "danger"
        // notify("tl", message, "success")
        
        history.push("/products", state);
        })
        //stop loading when data is fetched
    }

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            { IsLoaded ? 
            <div className="content">
                <Row>
                    <Col>
                        <Card className="card-plain">
                            <CardHeader><Row><Col><h2 className="title" style={{textAlign: "center"}}>{product.productName}</h2></Col>

                                <Col><div className="button-container">
                                    <Button className="btn-icon btn-round" onClick={toggleShowUpdate}>
                                        <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                    </Button>&nbsp;&nbsp;&nbsp;
                                    <Button className="btn-icon btn-round" onClick={deleteProduct}>
                                        <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                    </Button>
                                </div></Col></Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {/* <CardText /> */}
                                    <Col>
                                        <div className="card-description">
                                            <img
                                                alt={`${product.productName}`}
                                                // className="avatar"
                                                src={`${process.env.REACT_APP_NOVAS_IMAGE_BASE}${product.productUrl}`}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader><h4>Product Details</h4></CardHeader>
                                            <CardBody>

                                                <p className="description" style={{marginBottom: "15px", marginLeft: "5px"}}>{product.description}</p>
                                                <p className="description" style={{marginBottom: "15px", marginLeft: "5px"}}>{product.specs}</p>
                                                <p className="description" style={{marginTop: "15px", marginLeft: "5px"}}>Benefits</p>

                                                {product.benefits.map((benefit) => {
                                                    return (
                                                        <div style={{marginTop: "5px", marginLeft: "5px"}} key={benefit}>{benefit}</div>
                                                    );
                                                })}
                                                    <p className="description" style={{ marginTop: "15px", marginLeft: "5px" }}>Sizes</p>

                                                {product.sizes.map((size) => {
                                                    return (
                                                        <div style={{ color: "#747FA7", marginTop: "10px", marginLeft: "7px"}} key={size}>{size}</div>
                                                    );
                                                })}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {showUpdate ? <Card>
                            <ProductManager value={product} />
                        </Card> : <></>}
                    </Col>

                    {/* <Col>
                        <Card >
                            <CardBody>
                                
                                <div>
                                    <Input
                                        placeholder="Search by product name..."
                                        onChange={(e) => onSearchData(e)}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <Card className='card-plain'>
                            <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                                <TableContainer sx={{ maxHeight: 700 }}>
                                    <Table stickyHeader aria-label="sticky table" id="table-client">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(filter ? filtered : products)
                                                .map((item) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.productId}>
                                                            <TableCell style={{ color: "white" }}>{item.productName}</TableCell>
                                                            <TableCell style={{ color: "white" }}>{item.description}</TableCell>
                                                            <TableCell>
                                                                <div className="button-container"> &nbsp;
                                                                    <Link to={{
                                                                        pathname: '/products',
                                                                        // search: '?',
                                                                        state: item
                                                                    }}><Button style={{ color: "white" }}>
                                                                            <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                        </Button></Link>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Card>
                    </Col> */}
                </Row>

                {/* <Row>
                    <Card>
                        <CardBody><div className="button-container"><span style={{paddingRight: "2em"}}>Conversation on {startDate} from {startTime} to {endTime}</span>
                            
                                <Button className="btn-icon btn-round" onClick={onConversationClick}>
                                    <i className="tim-icons icon-cloud-download-93" />
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Row>

                {listItems} */}
            </div>
            // {/* } */}
            : <></>}
        </>
    );
}

export default Product;