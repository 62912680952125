import React, { useState, useEffect } from 'react';

import { Pie } from "react-chartjs-2";
import { getItem } from "../../services/api.svs";
import { Auth } from 'aws-amplify';

function MessageStatusComponent(props) {

  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);

  let qualified_options = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        // align: 'start',
        text: 'Messages by Status'
      }
    }
  };

  let qualifiedChart = {
    stats: (canvas) => {
      return {
        labels: label,
        datasets: [
          {
            label: "messages",
            backgroundColor: ['rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(255, 206, 86, 0.2)'],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(255, 206, 86, 1)'],
            borderWidth: 1,
            data: data
          }
        ]
      };
    },
    options: qualified_options
  };

  useEffect(() => {
    const getStats = async () => {
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/dashboard/messageStats', token).then(resp => {
        for (const [key, value] of Object.entries(resp.data)) {
          setData(data => [...data, value.value]);
          setLabel(label => [...label, value.key]);

        }


      }).catch(resp => {
        
      })
      //stop loading when data is fetched
    }
    getStats()
  }, [])

  return (
    <>
      <Pie
        data={qualifiedChart["stats"]}
        options={qualifiedChart.options}
      />
    </>
  );
};

export default MessageStatusComponent;
