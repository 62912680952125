import React, { useState, useEffect } from 'react';
import { getItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Blocks } from 'react-loader-spinner';
function Products(props) {
  const [textColour, setColour] = useState("white")
  const [IsLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([])
  const [filtered, setFiltered] = useState([])
  const [filter, setFilter] = useState(false)
  const [show, setShow] = useState(false);
  const notificationAlertRef = React.useRef(null);

  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const getProducts = async () => {
      if (props.location.state !== null) {
        let state = props.location.state;
        let message = (<div><div>{`${state === "success" ? 'Success' : 'Error'}`}</div></div>)
        notify("tl", message, state)
      }
      setColour(document.body.classList.contains("white-content") ? "black" : "white");
      const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
      getItem('/admin/products/filtered', token).then(resp => {
        setProducts(resp.data)
        setIsLoaded(true)
      }).catch(resp => {
        setProducts([])
        setIsLoaded(true)
        let message = (<div>
          <div>
            Error retrieved getting the data
          </div>
        </div>)
        notify("tl", message, "danger")
      })
      //stop loading when data is fetched
    }
    getProducts()
  }, [])

  // const onButtonClick = async (contactId) => {
  //   const token = window.localStorage["Access_Token"]
  //   requestFileItem(`storage/download/zip?id=${contactId}`, token).then(response => {
  //     const filename = response.headers.get('Content-Disposition').split('filename=')[1];
  //     let url = window.URL.createObjectURL(new Blob([response.data]));
  //     let a = document.createElement('a');
  //     a.href = url;
  //     a.download = filename;
  //     a.click();
  //   });
  // }

  //on Search Data in Table 
  const onSearchData = (e) => {
    var searchData = [];
    if (e.target.value !== "") {
      function find(arr) {
        var result = [];
        for (var i in arr) {
          if (arr[i].productName.toLowerCase().match(e.target.value.toLowerCase()) ||
            arr[i].productTypeProductTypeName.toLowerCase().match(e.target.value.toLowerCase())) {
            result.push(arr[i]);
          }
        }
        return result;
      }
      searchData = find(products);
      setFiltered(searchData)
      setFilter(true)
    } else {
      searchData = [];
      setFilter(searchData)
      setFilter(false)
    }
  }


  return (
    <>
    <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <div className="content">
          <Row>
            <Col md="6">
              <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
                <CardHeader>
                  <Row>
                    <Col md="10">
                      <CardTitle tag="h4">Products</CardTitle>
                      <p className="category">Products currently offered by Novas.</p>
                    </Col>
                    <Col><Link to={{
                      pathname: '/product-manager',
                      // search: '?',
                      // state: item
                    }}><Button style={{ color: `${textColour}` }}>
                        <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                      </Button></Link></Col></Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <Input
                      placeholder="Search by product name or product type..."
                      onChange={(e) => onSearchData(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className='card-plain'>
                <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                  <TableContainer sx={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table" id="table-client">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Product Type</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(filter ? filtered : products)
                          .map((item) => {
                            return (
                              <TableRow hover tabIndex={-1} key={item.productId}>
                                <TableCell style={{ color: `${textColour}` }}>{item.productName}</TableCell>
                                <TableCell style={{ color: `${textColour}` }}>{item.description}</TableCell>
                                <TableCell style={{ color: `${textColour}` }}>{item.productTypeName}</TableCell>
                                <TableCell>
                                  <div className="button-container">

                                    <Link to={{
                                      pathname: '/product',
                                      // search: '?',
                                      state: item
                                    }}><Button style={{ color: `${textColour}` }}>
                                        <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                      </Button></Link>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
    </>
  );
}

export default Products;