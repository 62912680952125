import React, { useState, useEffect } from 'react';
import NotificationAlert from "react-notification-alert";
import { getItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Blocks } from 'react-loader-spinner';

function Messages() {
    const [textColour, setColour] = useState("white")
    const [IsLoaded, setIsLoaded] = useState(false);
    const [unseenMessages, setUnseenMessages] = useState([])
    const [unprocessedMessages, setUnprocessedMessages] = useState([])
    const [processedMessages, setProcessedMessages] = useState([])

    const notificationAlertRef = React.useRef(null);
    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    // const onSearchData = (e) => {
    //     var searchData = [];
    //     if (e.target.value !== "") {
    //       function find(arr) {
    //         var result = [];
    //         for (var i in arr) {
    //           if (arr[i].productName.toLowerCase().match(e.target.value.toLowerCase()) ||
    //             arr[i].productType.productTypeName.toLowerCase().match(e.target.value.toLowerCase())) {
    //             result.push(arr[i]);
    //           }
    //         }
    //         console.log(result)
    //         return result;
    //       }
    //       searchData = find(products);
    //       setFiltered(searchData)
    //       setFilter(true)
    //     } else {
    //       searchData = [];
    //       setFilter(searchData)
    //       setFilter(false)
    //     }
    //   }

    useEffect(() => {

        const getMessages = async () => {
            setColour(document.body.classList.contains("white-content") ? "black" : "white");
            const token = await (await Auth.currentSession()).getIdToken().getJwtToken()
            getItem('/messages/unseen', token).then(resp => {
                setUnseenMessages(resp.data)
                setIsLoaded(true)
            }).catch(resp => {
                setUnseenMessages([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
            getItem('/messages/seenAndUnprocessed', token).then(resp => {
                setUnprocessedMessages(resp.data)
                setIsLoaded(true)
            }).catch(resp => {
                setUnprocessedMessages([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
            getItem('/messages/processed', token).then(resp => {
                setProcessedMessages(resp.data)
                setIsLoaded(true)
            }).catch(resp => {
                setProcessedMessages([])
                setIsLoaded(true)
                let message = (<div>
                    <div>
                        Error retrieved getting the data
                    </div>
                </div>)
                notify("tl", message, "danger")
            })
            //stop loading when data is fetched
        }
        getMessages()

    }, [])

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            {IsLoaded ? (
            <div className='content'>
            <Row>
          <Col md="6">
            <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
              <CardHeader>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">Unseen Messages</CardTitle>
                  {/* <p className="category">Products currently offered by Novas.</p> */}
                  </Col>
                  {/* <Col><Link to={{
                    pathname: '/product-manager',
                    // search: '?',
                    // state: item
                  }}><Button style={{ color: `${textColour}` }}>
                      <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                    </Button></Link></Col> */}
                    </Row>
              </CardHeader>
              {/* <CardBody>
                <div>
                  <Input
                    placeholder="Search by product name or product type..."
                    onChange={(e) => onSearchData(e)}
                  />
                </div>
              </CardBody> */}
            </Card>
          </Col>
        </Row>
                <Row>
                    <Card className='card-plain'>
                        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                            <TableContainer sx={{ maxHeight: 400 }}>
                                <Table stickyHeader aria-label="sticky table" id="table-unseen">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Topic</TableCell>
                                            <TableCell>Timestamp</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(unseenMessages)
                                            .map((item) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={item.messageId}>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.customerName}</TableCell>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.topic}</TableCell>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.timestamp}</TableCell>
                                                        <TableCell>
                                                            <div className="button-container">

                                                                <Link to={{
                                                                    pathname: '/message',
                                                                    // search: '?',
                                                                    state: item
                                                                }}><Button style={{ color: `${textColour}` }}>
                                                                        <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                    </Button></Link>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Card>
                </Row>
                <Row>
          <Col md="6">
            <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
              <CardHeader>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">Unprocessed Messages</CardTitle>
                  {/* <p className="category">Products currently offered by Novas.</p> */}
                  </Col>
                  {/* <Col><Link to={{
                    pathname: '/product-manager',
                    // search: '?',
                    // state: item
                  }}><Button style={{ color: `${textColour}` }}>
                      <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                    </Button></Link></Col> */}
                    </Row>
              </CardHeader>
              {/* <CardBody>
                <div>
                  <Input
                    placeholder="Search by product name or product type..."
                    onChange={(e) => onSearchData(e)}
                  />
                </div>
              </CardBody> */}
            </Card>
          </Col>
        </Row>
                <Row>
                    <Card className='card-plain'>
                        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                            <TableContainer sx={{ maxHeight: 400 }}>
                                <Table stickyHeader aria-label="sticky table" id="table-unseen">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Topic</TableCell>
                                            <TableCell>Timestamp</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(unprocessedMessages)
                                            .map((item) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={item.messageId}>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.customerName}</TableCell>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.topic}</TableCell>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.timestamp}</TableCell>
                                                        <TableCell>
                                                            <div className="button-container">

                                                                <Link to={{
                                                                    pathname: '/message',
                                                                    // search: '?',
                                                                    state: item
                                                                }}><Button style={{ color: `${textColour}` }}>
                                                                        <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                    </Button></Link>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Card>
                </Row>
                <Row>
          <Col md="6">
            <Card style={{ position: "sticky", top: 50, backgroundColor: "rgba(30, 30, 47, 1) !important", zIndex: 0 }}>
              <CardHeader>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">Processed Messages</CardTitle>
                  {/* <p className="category">Products currently offered by Novas.</p> */}
                  </Col>
                  {/* <Col><Link to={{
                    pathname: '/product-manager',
                    // search: '?',
                    // state: item
                  }}><Button style={{ color: `${textColour}` }}>
                      <i className="tim-icons icon-simple-add" style={{ color: "rgba(75, 192, 192, 1)" }} />
                    </Button></Link></Col> */}
                    </Row>
              </CardHeader>
              {/* <CardBody>
                <div>
                  <Input
                    placeholder="Search by product name or product type..."
                    onChange={(e) => onSearchData(e)}
                  />
                </div>
              </CardBody> */}
            </Card>
          </Col>
        </Row>
                <Row>
                    <Card className='card-plain'>
                        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                            <TableContainer sx={{ maxHeight: 400 }}>
                                <Table stickyHeader aria-label="sticky table" id="table-unseen">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Topic</TableCell>
                                            <TableCell>Timestamp</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(processedMessages)
                                            .map((item) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={item.messageId}>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.customerName}</TableCell>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.topic}</TableCell>
                                                        <TableCell style={{ color: `${textColour}` }}>{item.timestamp}</TableCell>
                                                        <TableCell>
                                                            <div className="button-container">

                                                                <Link to={{
                                                                    pathname: '/message',
                                                                    // search: '?',
                                                                    state: item
                                                                }}><Button style={{ color: `${textColour}` }}>
                                                                        <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                    </Button></Link>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Card>
                </Row>

            </div>
            ) : (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          /></div>

      )
      }
        </>
    )

}

export default Messages;