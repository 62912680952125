export let AmplifyComponent = {
    Header() {

      return (
       <div style={{width: "100%"}}>
        
        <br/>
       </div>
      );
    },

    Footer() {

      return (
        <>
        <br/>
        <div style={{width: "100%", bottom: '0px'}}>
        
       </div>
       </>
      );
    },
  } 
