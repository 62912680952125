import React, { useState, useEffect } from 'react';
import { getItem, getFileItem, deleteItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    Row,
    Col,
    Input
} from "reactstrap";
import { Button } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ProductTypeManager from './ProductTypeManager';
import { useHistory } from "react-router-dom";

function ProductType(props) {
    const [textColour, setColour] = useState("white")
    const [IsLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([])
    const [filtered, setFiltered] = useState([])
    const [filter, setFilter] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false);
    const [token, setToken] = useState([])
    // const [messagesState, setMessages] = useState([])
    const notificationAlertRef = React.useRef(null);
    const history = useHistory();

    const notify = (place, message, type) => {
        //  type = "primary";
        // type = "success";
        // type = "danger";
        // type = "warning";
        // type = "info";

        var options = {};
        options = {
            place: place,
            message: (
                message
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        let products = [];
        // let messages = [];
        let message = (<div>
            <div>
                Error retrieved getting the data
            </div>
        </div>)

        const getProducts = async () => {
            setColour(document.body.classList.contains("white-content") ? "black" : "white");
            setToken((await Auth.currentSession()).getIdToken().getJwtToken())
        await getItem(`/admin/products/productTypeName/${props.location.state.productTypeName}`, token).then(resp => {
                products = resp.data;
                setProducts(products)

            }).catch(resp => {
                setProducts([])
                setIsLoaded(true)
                notify("tl", message, "danger")
            })
            //stop loading when data is fetched
        }
        getProducts()

    }, [])

    //on Search Data in Table 
    const onSearchData = (e) => {
        var searchData = [];
        if (e.target.value !== "") {
            function find(arr) {
                var result = [];
                for (var i in arr) {
                    if (arr[i].productName.toLowerCase().match(e.target.value.toLowerCase())) {
                        result.push(arr[i]);
                    }
                }
                return result;
            }
            searchData = find(products);
            setFiltered(searchData)
            setFilter(true)
        } else {
            searchData = [];
            setFilter(searchData)
            setFilter(false)
        }
    }

    const toggleShowUpdate = () => {
        setShowUpdate(showUpdate ? false : true)
    }

    const deleteProductType = () => {
        deleteItem(`/product_types/${props.location.state.productTypeId}`, token).then(resp => {
            let state = "success"
        // notify("tl", message, "success")
        
        history.push("/product-types", state);

        }).catch(resp => {
            setProducts([])
            setIsLoaded(true)
            let state = "danger"
        // notify("tl", message, "success")
        
        history.push("/products", state);
        })
        //stop loading when data is fetched
    }

    // .then(response => response.blob()).then(response =>

    // const onButtonClick = () => {
    //     const token = window.localStorage["Access_Token"]
    //     getFileItem(`storage/download/client?id=${props.location.state.contactId}`, token).then(response => {
    //         const filename = response.headers.get('Content-Disposition').split('filename=')[1];
    //         // response.blob().then(blob => {
    //         let url = window.URL.createObjectURL(new Blob([response.data]));
    //         let a = document.createElement('a');
    //         a.href = url;
    //         a.download = filename;
    //         a.click();
    //         //   }).catch(resp => {
    //         //     console.log(resp)
    //         // });
    //     });
    // }

    // const onConversationClick = () => {
    //     const token = window.localStorage["Access_Token"]
    //     getFileItem(`storage/download/conversation?id=${props.location.state.contactId}`, token).then(response => {
    //         const filename = response.headers.get('Content-Disposition').split('filename=')[1];
    //         // response.blob().then(blob => {
    //         let url = window.URL.createObjectURL(new Blob([response.data]));
    //         let a = document.createElement('a');
    //         a.href = url;
    //         a.download = filename;
    //         a.click();
    //         //   }).catch(resp => {
    //         //     console.log(resp)
    //         // });
    //     });
    // }

    // const listItems = messagesState.map((message) =>
    //     <>
    //         <Card  style={{textAlign: message.sender.name ==="Prof" ? "right" : "left"}}>
    //             <CardHeader>{message.timestamp}</CardHeader>
    //             <CardBody>
    //                 <CardTitle tag="h5">
    //                     {message.sender.name}
    //                 </CardTitle>
    //                 <CardText>
    //                     {message.text}
    //                 </CardText>
    //             </CardBody>
    //         </Card>
    //     </>
    // );

    return (
        <>
            <NotificationAlert ref={notificationAlertRef} />
            <div className="content">
                <Row>
                    <Col md="5">
                        <Card className="card-plain">
                            <CardHeader><Row><Col md="8"><h2 className="title">{props.location.state.productTypeName}</h2></Col>

                                <Col>
                                    <div className="button-container">
                                        <Button className="btn-icon btn-round" onClick={toggleShowUpdate}>
                                            <i className="tim-icons icon-pencil" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                        </Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button className="btn-icon btn-round" onClick={deleteProductType}>
                                            <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                        </Button>
                                    </div>
                                </Col></Row>
                            </CardHeader>
                            <CardBody>
                                {/* <CardText /> */}
                                <div className="card-description">
                                    {/* <div className="block block-one" />
                                    <div className="block block-two" />
                                    <div className="block block-three" />
                                    <div className="block block-four" /> */}

                                    <img
                                        alt={`${props.location.state.productTypeName}`}
                                        // className="avatar"
                                        src={`${process.env.REACT_APP_NOVAS_IMAGE_BASE}${props.location.state.productTypeUrl}`}
                                    />
                                    {/* {props.location.state.profession}
                                    <p className="description">{props.location.state.emailAddress} {props.location.state.phoneNumber}</p> */}
                                </div>

                            </CardBody>
                        </Card>
                        {showUpdate ? <Card>
                            <ProductTypeManager value={props.location.state} />
                        </Card> : <></>}
                    </Col>
                    <Col>

                        <Row>

                        </Row>
                        <Row>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Merchant Urls</CardTitle>
                                    <CardText>Links to product range on merchant websites</CardText>
                                </CardHeader>
                                <CardBody>
                                    <p className="description" style={{marginTop: "15px", marginLeft: "5px"}}>Takealot</p>
                                    <div style={{marginTop: "5px", marginLeft: "5px"}}><a style={{color: "#525f7f", textDecoration: "underline"}} href={props.location.state.leroyAndMerlinUrl}>{props.location.state.takealotUrl}</a></div>
                                    <p className="description" style={{ marginTop: "15px", marginLeft: "5px" }}>Makro</p>
                                    <div style={{marginTop: "5px", marginLeft: "5px"}}><a style={{color: "#525f7f", textDecoration: "underline"}} href={props.location.state.leroyAndMerlinUrl}>{props.location.state.makroUrl}</a></div>
                                    <p className="description" style={{ marginTop: "15px", marginLeft: "5px" }}>Leroy & Merlin</p>
                                    <div style={{marginTop: "5px", marginLeft: "5px"}}><a style={{color: "#525f7f", textDecoration: "underline"}} href={props.location.state.leroyAndMerlinUrl}>{props.location.state.leroyAndMerlinUrl}</a></div>
                                </CardBody>
                            </Card>
                        </Row>
                        <Row>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Products</CardTitle>
                                    <CardText>Products available under the {props.location.state.productTypeName} range</CardText>
                                </CardHeader>
                                <CardBody>

                                    <div>
                                        <Input
                                            placeholder="Search by product name..."
                                            onChange={(e) => onSearchData(e)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Row>
                        <Row>
                            <Card className='card-plain'>
                                <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "transparent" }}>
                                    <TableContainer sx={{ maxHeight: 700 }}>
                                        <Table stickyHeader aria-label="sticky table" id="table-client">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(filter ? filtered : products)
                                                    .map((item) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={item.productId}>
                                                                <TableCell style={{ color: `${textColour}` }}>{item.productName}</TableCell>
                                                                <TableCell style={{ color: `${textColour}` }}>{item.description}</TableCell>
                                                                <TableCell>
                                                                    <div className="button-container"> &nbsp;
                                                                        <Link to={{
                                                                            pathname: '/product',
                                                                            // search: '?',
                                                                            state: item
                                                                        }}><Button style={{ color: `${textColour}` }}>
                                                                                <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                                                            </Button></Link>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Card></Row>
                    </Col>
                </Row>

                {/* <Row>
                    <Card>
                        <CardBody><div className="button-container"><span style={{paddingRight: "2em"}}>Conversation on {startDate} from {startTime} to {endTime}</span>
                            
                                <Button className="btn-icon btn-round" onClick={onConversationClick}>
                                    <i className="tim-icons icon-cloud-download-93" />
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Row>

                {listItems} */}
            </div>
            {/* } */}
        </>
    );
}

export default ProductType;