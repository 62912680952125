import React, { useState, useEffect } from 'react';
import ReactNotificationAlert from "react-notification-alert";
import { updateItem, deleteItem } from "../services/api.svs";
import { Auth } from 'aws-amplify';

import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    Row,
    Col,
    CardSubtitle
} from "reactstrap";

import { Button } from '@aws-amplify/ui-react';
import { Blocks } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';

function Message(props) {
    const [IsLoaded, setIsLoaded] = useState(true);
    const [message, setMessage] = useState(props.location.state)
    const [token, setToken] = useState()
    const [seen, setSeen] = useState(message.seen)
    const [processed, setProcessd] = useState(message.processed)
    const history = useHistory();
    const notificationAlertRef = React.useRef(null);
    const notify = (place, notifyMessage, type) => {
        var options = {};
        options = {
            place: place,
            message: (
                notifyMessage
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        const getToken = async () => {
            setToken((await Auth.currentSession()).getIdToken().getJwtToken())
        }
        getToken()
    }, [])

    const seeMessage = () => {
        updateItem(`/messages/see/${message.messageId}`, {},token).then(resp => {
            let notifyMessage = (<div>
                <div>
                    Message seen
                </div>
            </div>)
            notify("tl", notifyMessage, "success")
            setSeen(true)
        }).catch(resp => {
            setIsLoaded(true)
            let notifyMessage = (<div>
                <div>
                    Error retrieved updating the message
                </div>
            </div>)
            notify("tl", notifyMessage, "danger")
        })
        //stop loading when data is fetched
    }

    const processMessage = () => {
        updateItem(`/messages/process/${message.messageId}`, {}, token).then(resp => {
            let notifyMessage = (<div>
                <div>
                    Message processed successfully
                </div>
            </div>)
            notify("tl", notifyMessage, "success")
            setProcessd(true)
        }).catch(resp => {
            setIsLoaded(true)
            let notifyMessage = (<div>
                <div>
                    Error retrieved processing the message
                </div>
            </div>)
            notify("tl", notifyMessage, "danger")
        })
        //stop loading when data is fetched
    }

    const deleteMessage = () => {
        deleteItem(`/messages/${message.messageId}`, token).then(resp => {
            let state = "success"
            history.push("/messages", state);

        }).catch(resp => {
            setIsLoaded(true)
            let notifyMessage = (<div>
                <div>
                    Error retrieved processing the message
                </div>
            </div>)
            notify("tl", notifyMessage, "danger")
        })
    }

    return (
        <>
            <ReactNotificationAlert ref={notificationAlertRef} />
            {IsLoaded ?
                (<div className="content">

                    <Row>
                        <Col md="6">
                            <Card
                            // style={{
                            //     width: '18rem',
                            //     // display: 'grid',
                            //     // align: 'center'
                            //     // margin: '20rem'
                            // }}
                            >
                                <CardBody className='text-right'>
                                    {seen ? <></> : <Button className="btn-icon btn-round" style={{ margin: '1rem' }} onClick={seeMessage}>
                                        <i className="tim-icons icon-zoom-split" style={{ color: "rgba(75, 192, 192, 1)" }} />
                                    </Button>}
                                    {processed ? <></> : <Button className="btn-icon btn-round" style={{ margin: '1rem' }} onClick={processMessage}>
                                        <i className="tim-icons icon-check-2" style={{ color: "green" }} />
                                    </Button>}
                                    <Button className="btn-icon btn-round" style={{ margin: '1rem' }} onClick={deleteMessage}>
                                        <i className="tim-icons icon-trash-simple" style={{ color: "red" }} />
                                    </Button>
                                </CardBody>
                                <CardBody className='text-center'>
                                    <CardTitle tag="h1">
                                        {message.customerName}
                                    </CardTitle>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        {message.email}
                                    </CardSubtitle>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        {message.number}
                                    </CardSubtitle>
                                </CardBody>

                                <CardBody>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h3"
                                    >
                                        {message.topic}
                                    </CardSubtitle>
                                    <CardText>
                                        {message.message}
                                    </CardText>
                                    <CardText>
                                        <small className="text-muted">
                                            {message.timestamp}
                                        </small>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>) : (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    }}>
                        <Blocks
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        /></div>
                )}
        </>
    )
}

export default Message;